import React from "react";
import { ReactComponent as Icon1 } from "../assets/img/Icon1.svg";
import { ReactComponent as Icon2 } from "../assets/img/Icon2.svg";
import { ReactComponent as Icon3 } from "../assets/img/thumb.svg";
import { ReactComponent as Icon4 } from "../assets/img/Icon4.svg";

const WhyUniswitch = () => {
  const productsData = [
    {
      id: 1,
      icon: <Icon1 />,
      header: "Secure",
      text: "Our products and services have multilayer factor security to protect your data from threats and security breaches.",
      height: "40px",
    },
    {
      id: 2,
      icon: <Icon2 />,
      header: "Instant",
      text: "Instantly carry out transactions without any difficulty across all our products.",
      height: "50px",
    },
    {
      id: 3,
      icon: <Icon3 />,
      header: "Reliable",
      text: "Making seamless transactions has never been easier. Experience low to non-existent failure rates on our platform.",
      height: "30px",
    },
    {
      id: 4,
      icon: <Icon4 />,
      header: "Simple",
      text: "Our products are easy and simple to use. Giving you the best user experience is our priority.",
      height: "40px",
    },
  ];

  return (
    <div className="px-4 background-color1">
      <h2 className="card md:text-4xl text-3xl font-black text-center py-16">Why Uniswitch</h2>

      <div className="flex flex-wrap justify-between lg:px-24 mt-3">
        {productsData.map((product) => (
          <div key={product.id} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/4">
            <div className="items-center bg-black shadow-md shadow-black h-64 rounded-3xl px-6 py-10 mb-4 mx-12 md:mx-8 ">
              <div className="h-16 flex justify-start items-center">
                {product.icon}
              </div>
              <h3 className="font-bold text-white text-xl mt-4">{product.header}</h3>
              <p className="text-white text-xs mt-4">{product.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyUniswitch;
