import React from "react";
import { IoArrowRedo } from "react-icons/io5";

const ProductItemRight = ({ name, title, description, image, url }) => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row p-8 md:px-32">
      <div className="w-full md:w-1/2">
        <h3 className="text-3xl md:text-5xl md:leading-snug font-bold text-center md:text-left md:w-[380px]">
          {title}
        </h3>
        <p className="mt-6 justify-start text-center md:text-left md:w-[450px]">
          {description}
        </p>
        <div className="flex justify-center md:justify-start my- mb-6">
          <p className="mr-2">Visit</p>
          <IoArrowRedo className="mr-2 mt-1" />
          <a href={url} target="_blank" rel="noopener noreferrer">
            <p className="text-blue-400">{name}</p>
          </a>
        </div>
      </div>

      <div className="w-full md:w-1/2 mt-4">
        <img src={image} alt="phone" />
      </div>
    </div>
  );
};

export default ProductItemRight;
