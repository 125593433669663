import React from "react";
import { Link } from "react-router-dom";

const Expert = () => {
  return (
    <div className="flex items-center py-8 justify-center md:p-12">
      <div className="text-left md:py-24 px-12 md:px-24">
        <p className="text-4xl md:text-5xl text-white leading-[4rem] text-center font-semibold">
          Expert Guidance at Your Fingertips
        </p>
        <p className="text-white leading-[3rem] text-center mx-auto mt-12">
          With Uniswitch, you have access to a team of payment experts ready to
          assist you. Whether you have questions about integration, need
          assistance with troubleshooting, or require advice on optimizing your
          payment processes, our knowledgeable support staff is just a call or
          message away. We pride ourselves on delivering prompt and accurate
          responses to ensure that you have the best experience possible.
        </p>

        <Link to="/contactUs">
        <div className="flex justify-center mt-12">
          <button
            className="
              p-4
              hover:bg-black
              hover:border-black
              text-white
              text-xs
              bg-green-600
              w-36
              rounded-md
            "
            type="submit"
          >
            CONTACT SALES
          </button>
        </div>
        </Link>

       
      </div>
    </div>
  );
};

export default Expert;
