// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./fonts/century-gothic/Century Gothic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./fonts/century-gothic/CenturyGothic.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./fonts/century-gothic/GOTHIC.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./fonts/century-gothic/GOTHICB.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./fonts/century-gothic/GOTHICB0.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./fonts/century-gothic/GOTHICBI.TTF", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./fonts/century-gothic/GOTHICI.TTF", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'Century Gothic';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_5___}) format('truetype'),
         url(${___CSS_LOADER_URL_REPLACEMENT_6___}) format('truetype');

    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'Century Gothic', Arial, sans-serif;
  }
  `, "",{"version":3,"sources":["webpack://./src/fonts.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B;;;;;;mEAMiE;;IAEjE,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,gDAAgD;EAClD","sourcesContent":["@font-face {\n    font-family: 'Century Gothic';\n    src: url('./fonts/century-gothic/Century\\ Gothic.ttf') format('truetype'),\n         url('./fonts/century-gothic/CenturyGothic.ttf') format('truetype'),\n         url('./fonts/century-gothic/GOTHIC.TTF') format('truetype'),\n         url('./fonts/century-gothic/GOTHICB.TTF') format('truetype'),\n         url('./fonts/century-gothic/GOTHICB0.TTF') format('truetype'),\n         url('./fonts/century-gothic/GOTHICBI.TTF') format('truetype'),\n         url('./fonts/century-gothic/GOTHICI.TTF') format('truetype');\n\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  body {\n    font-family: 'Century Gothic', Arial, sans-serif;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
