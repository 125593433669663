import React from "react";
import notFound from "../assets/img/Oops.svg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";


const NotFound = () => {
  return (
    <div>
      <div className="text-center mt-8">
      <p>The page you are looking for does not exist.</p>

        <button
          className="bg-gray-500 text-white active:bg-pink-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none mt-2 hover:bg-slate-400 focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
          type="button"
        >
            <Link to="/">
            Go Back Home

            </Link>
        </button>
      
        <img src={notFound} alt="pageNotfound" className="h-[60vh] mx-auto" />
      </div>
      <Footer />
    </div>
  );
};

export default NotFound;
