import React from "react";

const TrustedPartners = (props) => {

  return (
    <div className="px-4 py-6 background-color1">
      <div className="flex flex-wrap items-center justify-between lg:px-24 my-6">
        {/* {productsData.map((product) => ( */}
          <div key={props.id} className="">
            <div className="flex justify-center items-center">
              <img src={props.photo} alt={props.alt} />
            </div>
          
          </div>
        {/* ))} */}
      </div>
    </div>
  );
};

export default TrustedPartners;



