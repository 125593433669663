import React from "react";
import Navbar from "../components/Navbar";
import HeroText from "../components/HeroText";
import OurProducts from "../components/OurProducts";
import TrustedPartners from "../components/TrustedPartners";
import Trusted from "../components/Trusted";
import Unlock from "../components/Unlock";
import AcceptPayment from "../components/AcceptPayment";
import WhyUniswitch from "../components/WhyUniswitch";
import Expert from "../components/Expert";
import Footer from "../components/Footer";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import WhyUni from "../components/WhyUni";
import {
  productsData,
  responsive,
  trustedData,
} from "../components/whyuni-data";

const Home = () => {
  const whyuniProducts = productsData.map((item) => (
    <WhyUni
      id={item.id}
      icon={item.icon}
      header={item.header}
      text={item.text}
      height={item.height}
    />
  ));

  const trustedPartnersProducts = trustedData.map((item) => (
    <Trusted id={item.id} alt={item.alt} photo={item.photo} />
  ));
  return (
    <div className="background-color1">
      <div className="main-container">
        <Navbar />
        <HeroText />
      </div>
      {/* <OurProducts /> */}
      <Unlock />
      <AcceptPayment />

      <div className="max-w-[1440px] mx-auto px-4 ">
        <h2 className="card md:text-4xl text-3xl font-black text-center py-4">
          Why Uniswitch
        </h2>
        <Carousel responsive={responsive}>{whyuniProducts}</Carousel>
      </div>

      {/* <WhyUniswitch /> */}
      <div className="hidden md:block">
      < TrustedPartners  />

      </div>

      <div className="md:hidden max-w-[1340px] mx-auto px-4 ">
        <h2 className="card md:text-4xl text-3xl font-semibold text-center pt-12">
          Trusted Partners
        </h2>

        <Carousel responsive={responsive}>{trustedPartnersProducts}</Carousel>
      </div>
      <div className="home-wrap-container">
        <Expert />
      </div>
      <Footer />
    </div>
  );
};

export default Home;
