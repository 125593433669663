import React from "react";
import { IoArrowRedo } from "react-icons/io5";

const ProductItem = ({ name, title, description, image, url, live }) => {
  return (
    <div className="py-2">
      <div className="flex flex-col-reverse md:flex-row lg:flex-row xl:flex-row p-8 md:px-32 gap-10">
        <div className="w-full md:w-3/5 mt-4">
          <img src={image} alt="phone" />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-5xl md:leading-snug font-bold text-center md:text-left md:w-[500px]">
            {title}
          </h3>
          <p className="mt-6 justify-start md:text-left md:w-[450px] text-center">
            {description}
          </p>
          <div className="flex justify-center md:justify-start mb-6">
            <p className="mr-2">Visit</p>
            <IoArrowRedo className="mr-2 mt-1" />
            <a href={url} target="_blank" rel="noopener noreferrer">
              <p className={live ? "text-gray-400" : "text-blue-400"}>{name}</p>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductItem;
