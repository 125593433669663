import React from "react";
import logo1 from "../assets/img/digicore-removebg-preview 1.svg";
import logo3 from "../assets/img/pfs_logo-removebg-preview.svg";
import logo2 from "../assets/img/interswitch-removebg-preview 1.svg";
import logo4 from "../assets/img/vbank-removebg-preview.svg";
import logo5 from "../assets/img/IMAGE.svg";
import logo6 from "../assets/img/vfd_group-removebg-preview.svg";
import logo7 from "../assets/img/hydrogen_pay-removebg-preview 1.svg";
import logo8 from "../assets/img/cico-removebg-preview 1.svg";
import logo9 from "../assets/img/wema_bank-removebg-preview 1.svg";




const TrustedPartners = () => {
  const productsData = [
    {
      id: 1,
      photo: logo2,
    },
    {
      id: 2,
      photo: logo8,
    },
    {
      id: 3,
      photo: logo4,
    },
    {
      id: 4,
      photo: logo3,
    },
    {
      id: 5,
      photo: logo5,
    },
    {
      id: 6,
      photo: logo6,
    },
    {
      id: 7,
      photo: logo7,
    },
    {
      id: 8,
      photo: logo1,
    },
    {
      id: 9,
      photo: logo9,
    },
   
  ];

  return (
    <div className="px-4 py-6 background-color1">
      <h2 className="card md:text-4xl text-3xl font-semibold text-center pt-8">Trusted Partners</h2>

      <div className="flex flex-wrap items-center justify-between lg:px-24 my-6">
        {productsData.map((product) => (
          <div key={product.id} className="w-full md:w-1/4 lg:w-1/5 xl:w-1/5 mb-8">
            <div className="flex justify-center items-center">
              <img src={product.photo} alt={product.alt} />
            </div>
          
          </div>
        ))}
      </div>
    </div>
  );
};

export default TrustedPartners;



