import React from 'react'
import { IoArrowRedo } from 'react-icons/io5'
import gif from "../assets/img/check-gif.gif";


const Sec = () => {
  return (
  <div>
   <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row p-8 md:px-8 md:py-4">
    <div className="w-full md:w-2/3 md:ml-20">
      <h3 className="text-3xl md:text-4xl md:leading-[3rem] md:mt-12 font-bold md:text-right text-center md:mb-10 md:w-[75%]">
      Security      </h3>
      <p className="mt-6 text-center leading-[3rem] md:text-left md:w-[720px]">
      At Uniswitch, we take the security and privacy of our customers very seriously. We understand the importance of safeguarding your data and ensuring a secure environment for your online activities. This page outlines our commitment to security and the measures we take to protect your information.
      </p>
    </div>

    <div className="flex items-center justify-center w-full md:w-1/3">
      <img src={gif} alt="phone" />
    </div>
  </div>
  </div>
   )
}

export default Sec