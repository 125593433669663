import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactUs = () => {
  return (
    <div>

      <div className="wrap-container">
      <Navbar />

        <div className="flex flex-col md:flex-row lg:px-32 py-6 md:pb-32 ">
     
          <div className="md:w-1/2 p-4">
            <h2 className="text-5xl font-bold mb-4">Contact Us</h2>
            <p className="text-sm mb-8 leading-8">
              Contact us today to learn more about Uniswitch and how we can
              transform your payment experience.
            </p>
            <form className="bg-gray-900 text-white p-6 rounded-lg">
              <p className="text-xs py-6">
                Fill out the form below with questions or inquiries you may have
                and a member of our team will get back to you in no time!
              </p>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="firstName"
                    className="block text-gray-700 font-bold mb-2"
                  ></label>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="First Name"
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="lastName"
                    className="block text-gray-700 font-bold mb-2"
                  ></label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Last Name"
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                  />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-gray-700 font-bold mb-2"
                  ></label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Email"
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                  />
                </div>
                <div>
                  <label
                    htmlFor="phone"
                    className="block text-gray-700 font-bold mb-2"
                  ></label>
                  <input
                    type="tel"
                    id="phone"
                    placeholder="Phone Number"
                    className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                  />
                </div>
              </div>
              <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 font-bold mb-2"
                ></label>
                <textarea
                  id="message"
                  placeholder="Message"
                  rows="4"
                  className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-green-500"
                ></textarea>
              </div>
              <div className="flex items-center mb-4">
                <input type="checkbox" id="agree" className="mr-2" />
                <label htmlFor="agree" className="text-white text-xs">
                  I agree that uniswitch may contact me at the email address or
                  phone number above.
                </label>
              </div>
              <div className="flex items-center justify-end">
              <button
                type="button"
                className="bg-white hover:bg-green-600 hover:text-white text-black font-bold py-2 px-4 rounded-full shadow-md"
              >
                Submit
              </button>
            </div>
            </form>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
