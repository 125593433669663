import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";
import isms from "../assets/img/isms.png";
import ismsPolicy from "../Resume.pdf";

const ISMS = () => {
  return (
    <div className="app-wrapper">
      <Navbar />
      <div className="py-8 flex-grow max-w-[83rem] md:mx-auto w-full">
        <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row md:px-5">
          <div className="flex justify-center md:justify-start py-2 md:mr-8">
            <img src={isms} alt="ISMS policy" />
          </div>

          <div className="">
            <h3 className="text-3xl md:text-3xl md:leading-[3rem] font-bold text-center md:text-left md:w-[380px]">
              Uniswitch ISMS Policy and Objectives
            </h3>

            <div className="flex justify-center md:justify-start my-6">
              <a
                href="https://uniswitch-public-assets.s3.amazonaws.com/ISMS.pdf"
                className="p-4 text-center hover:bg-black shadow-lg hover:border-black text-white bg-green-600 w-36 rounded-md"
                target="_blank"
                rel="noreferrer"
              >
                <button>DOWNLOAD</button>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer className="isms-footer" />
    </div>
  );
};

export default ISMS;
