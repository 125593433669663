import React from "react";
import icon1 from "../assets/img/security.svg";
import icon2 from "../assets/img/Instant.svg";
import icon3 from "../assets/img/thumb-black.svg";
import icon4 from "../assets/img/simple.svg";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";

const WhyUniswitch = () => {
  const productsData = [
    {
      id: 1,
      icon: icon1,
      header: "Secure",
      text: "Our products and services have multilayer factor security to protect your data from threats and security breaches.",
      height: "40px",
    },
    {
      id: 2,
      icon: icon2,
      header: "Instant",
      text: "Instantly carry out transactions without any difficulty across all our products.",
      height: "50px",
    },
    {
      id: 3,
      icon: icon3,
      header: "Reliable",
      text: "Making seamless transactions has never been easier. Experience low to non-existent failure rates on our platform.",
      height: "35px",
    },
    {
      id: 4,
      icon: icon4,
      header: "Simple",
      text: "Our products are easy and simple to use. Giving you the best user experience is our priority.",
      height: "45px",
    },
  ];

  return (
    <div>
      <div className="aboutus-container">
      <Navbar />

      <div className="p-6 text-white ">
        <h2 className="card md:text-4xl text-3xl font-bold text-center py-4">
          About Us
        </h2>
        <p className="text-center leading-[2rem] pb-4 text-sm md:mt-4 md:px-32">
          At Uniswitch our specialization lies in enabling seamless transactions
          and managing financial processes, as demonstrated by our successful
          track record across various sectors. We offer a diverse portfolio of
          software solutions encompassing revenue management, payroll
          management, pension administration, government collections, bill
          presentation, and payment processing.
        </p>
      </div>
      <div className="px-4 text-white ">
        <h2 className="card md:text-4xl text-3xl font-black text-center py-10">
          Why Uniswitch
        </h2>

        <div className="flex flex-wrap justify-between lg:px-24 mt-3 md:pb-8">
          {productsData.map((product) => (
            <div key={product.id} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/4">
              <div className="items-center bg-gray-100 h-64 text-black rounded-3xl px-6 py-6 mb-4 mx-12 md:mx-8">
                <div className="">
                  <img
                    src={product.icon}
                    alt="icon"
                   style={{height:product.height}}
                  />
                </div>
                <h3 className="font-bold text-xl mt-4">{product.header}</h3>
                <p className="text-xs mt-4">{product.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    
      <Footer />
    </div>
  );
};

export default WhyUniswitch;
