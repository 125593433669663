import { ReactComponent as Icon1 } from "../assets/img/Icon1.svg";
import { ReactComponent as Icon2 } from "../assets/img/Icon2.svg";
import { ReactComponent as Icon3 } from "../assets/img/thumb.svg";
import { ReactComponent as Icon4 } from "../assets/img/Icon4.svg";
import logo1 from "../assets/img/digicore-removebg-preview 1.svg";
import logo3 from "../assets/img/pfs_logo-removebg-preview.svg";
import logo2 from "../assets/img/interswitch-removebg-preview 1.svg";
import logo4 from "../assets/img/vbank-removebg-preview.svg";
import logo5 from "../assets/img/IMAGE.svg";
import logo6 from "../assets/img/vfd_group-removebg-preview.svg";
import logo7 from "../assets/img/hydrogen_pay-removebg-preview 1.svg";
import logo8 from "../assets/img/cico-removebg-preview 1.svg";
import logo9 from "../assets/img/wema_bank-removebg-preview 1.svg";

export  const productsData = [
    {
      id: 1,
      icon: <Icon1 />,
      header: "Secure",
      text: "Our products and services have multilayer factor security to protect your data from threats and security breaches.",
      height: "40px",
    },
    {
      id: 2,
      icon: <Icon2 />,
      header: "Instant",
      text: "Instantly carry out transactions without any difficulty across all our products.",
      height: "50px",
    },
    {
      id: 3,
      icon: <Icon3 />,
      header: "Reliable",
      text: "Making seamless transactions has never been easier. Experience low to non-existent failure rates on our platform.",
      height: "30px",
    },
    {
      id: 4,
      icon: <Icon4 />,
      header: "Simple",
      text: "Our products are easy and simple to use. Giving you the best user experience is our priority.",
      height: "40px",
    },
  ];

  export const trustedData = [
    {
      id: 1,
      photo: logo2,
    },
    {
      id: 2,
      photo: logo8,
    },
    {
      id: 3,
      photo: logo4,
    },
    {
      id: 4,
      photo: logo3,
    },
    {
      id: 5,
      photo: logo5,
    },
    {
      id: 6,
      photo: logo6,
    },
    {
      id: 7,
      photo: logo7,
    },
    {
      id: 8,
      photo: logo1,
    },
    {
      id: 9,
      photo: logo9,
    },
   
  ];

  export  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };