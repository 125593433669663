import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
// import { IoArrowRedo } from 'react-icons/io5'

const Faq = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null);
  const toggleQuestion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div>
      <Navbar />
      <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row p-12 md:p-32">
        <div className="w-full md:w-1/2">
          <h3 className="text-3xl md:text-5xl md:leading-[3rem] font-bold text-left w-[300px] md:w-[380px]">
            Frequently Asked Questions
          </h3>
          <p className="mt-6 text-center md:text-left md:w-[450px]">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the
          </p>
          <div className="my-6">
          
            <p className="font-semibold">View All Faq</p>
            <div className="border border-solid w-24 border-gray-500"></div>

          </div>
        </div>

        <div className="flex items-center justify-center w-full md:w-1/2 ">
          <div className="w-full">
            {data.map((item, index) => (
              <div key={index} className="mb-4">
                <button
                  className="flex items-center justify-between w-full py-4 px-2 text-left text-white bg-gray-900 rounded-lg hover:bg-gray-700 focus:bg-gray-700"
                  onClick={() => toggleQuestion(index)}
                >
                  <span className="text-lg font-medium">{item.question}</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className={`h-4 w-4 transform transition-transform ${
                      activeIndex === index ? "rotate-45" : ""
                    }`}
                  >
                    <line x1="12" y1="5" x2="12" y2="19" />
                    <line x1="5" y1="12" x2="19" y2="12" />
                  </svg>
                </button>
                {activeIndex === index && (
                  <div className="p-4 mt-2 bg-gray-800">
                    <p className="text-white">{item.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Faq;