import React from "react";
import pic from "../assets/img/Group PM pictures.svg";

const AcceptPayment = () => {
  return (
    <div className="flex flex-col md:flex-row lg:flex-row xl:flex-row px-6 py-8 md:px-24 background-color1">
      <div className="w-full md:w-2/5 md:mt-10">
        <h3 className="text-4xl mt-10  text-center md:text-left font-black md:w-[450px]">
          Accept all payment methods
        </h3>
        <p className="mt-6 text-center md:leading-[3rem] md:text-left md:w-[520px]">
          Say goodbye to limitations and welcome a world of possibilities.
          Uniswitch enables businesses to accept all payment methods, whether
          it's credit and debit cards, mobile wallets, bank transfers, or
          alternative payment options. Our platform empowers you to cater to the
          preferences of your customers, allowing you to transact effortlessly
          with anyone, anywhere in the world.
        </p>
      </div>

      <div className="w-full md:w-3/5 py-6">
        <img src={pic} alt="phone" />
      </div>
    </div>
  );
};

export default AcceptPayment;
