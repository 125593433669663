import React from "react";
import "./App.css";

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { createBrowserHistory } from "history";

import Products from "./pages/Products";
import ContactUs from "./pages/ContactUs";
import Faq from "./pages/Faq";
import faqData from "./pages/FaqData";
import Security from "./pages/Security";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ISMS from "./pages/ISMS";
import Privacy from "./components/Privacy";
import GetStarted from "./pages/GetStarted";
import NotFound from "./pages/NotFound";

function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return null;
}


function App() {
  return (
    <>
      <Router >
      <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route index element={<Home />} />
          {/* <Route path="*" element={<NoMatch />} /> */}
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/faq" element={<Faq data={faqData}/>} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/products" element={<Products />} />
          <Route path="/security" element={<Security />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/isms" element={<ISMS />} />

          <Route path="/*" element={<NotFound />} />



          <Route path="/getstarted" element={<GetStarted />} />

        </Routes>
      </Router>
    </>
  );
}

export default App;
