import React from "react";
import frame1 from "../assets/img/Frame 1.png";
import frame2 from "../assets/img/Frame 2.png";
import frame3 from "../assets/img/Frame 3.png";
import frame4 from "../assets/img/Frame 4.png";

const OurProducts = () => {
  const productsData = [
    {
      id: 1,
      photo: frame1,
      text: "Download App",
    },
    {
      id: 2,
      photo: frame2,
      text: "Registration",
    },
    {
      id: 3,
      photo: frame3,
      text: "Load your Wallet",
    },
    {
      id: 4,
      photo: frame4,
      text: "Ready to Go",
    },
  ];

  return (
    <div className="px-4 py-8 bg-orange-50">
      <h2 className="card md:text-4xl text-3xl font-bold text-center py-16">How Our Products Work</h2>

      <div className="flex flex-wrap justify-between lg:px-24 my-6">
        {productsData.map((product) => (
          <div key={product.id} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/4">
            <div className="flex justify-center items-center">
              <img src={product.photo} alt={product.alt} />
            </div>
            <div className="flex items-center justify-center mt-4 mb-8">
              <div className="w-8 h-8 rounded-full text-white bg-black flex justify-center items-center mr-4">
                {product.id}
              </div>
              <p className="font-bold text-xl">{product.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurProducts;
