import React from 'react'

const WhyUni = (props) => {
  return (
          <div className="flex flex-wrap justify-between lg:px-24 mt-3">
        {/* {productsData.map((product) => ( */}
          <div key={props.id} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/4">
            <div className="items-center bg-black shadow-md shadow-black h-64 w-[15rem] rounded-3xl px-6 py-10 mb-4 mx-12 md:mx-8 ">
              <div className="h-16 flex justify-start items-center">
                {props.icon}
              </div>
              <h3 className="font-bold text-white text-xl mt-4">{props.header}</h3>
              <p className="text-white text-xs mt-4">{props.text}</p>
            </div>
          </div>
        {/* ))} */}
      </div>
  )
}

export default WhyUni