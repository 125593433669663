import React, { useState } from "react";
import icon1 from "../assets/img/sarepay-logo.svg";
import icon2 from "../assets/img/billerHQ-04 2-logo.svg";
import icon3 from "../assets/img/Pension Manager -05 3-logo.svg";
import icon4 from "../assets/img/twonda-logo.svg";
import icon5 from "../assets/img/toctopus-logo (1).svg";
// import icon6 from "../assets/img/Icon2.svg";
// import icon7 from "../assets/img/Naira.svg";
// import icon8 from "../assets/img/Icon4.svg";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const GetStarted = () => {
  const productsData = [
    {
      id: 1,
      icon: icon1,
      header: "Send & Receive Payment with SarePay",
      text: "",
      backgroundColor: "bg-white",
      showLogin: true,
      signupLink: "https://www.sarepay.com/",
      hoverColor: "bg-green-600",
    },
    {
      id: 2,
      icon: icon2,
      header: "Streamline Your Payments with BillerHQ",
      text: "",
      backgroundColor: "bg-white",
      showLogin: true,
      signupLink: "https://billerhq.com/",
      hoverColor: "bg-green-600",
    },
    {
      id: 3,
      icon: icon3,
      header: "Seamlessly Manage your Pension with Pension Manager",
      text: "",
      backgroundColor: "bg-white",
      showLogin: true,
      signupLink: "https://pensionmanager.ng/",
      hoverColor: "bg-green-600",
    },

    {
      id: 4,
      icon: icon5,
      header: "The Ultimate Payment Management Solution",
      text: "",
      backgroundColor: "bg-white",
      showLogin: true,
      hoverColor: "bg-white",
    },
  ];

  return (
    <div className="background-color1">
      <Navbar />
      <div className="px-4 py-16">
        <h2 className="card md:text-4xl text-3xl font-bold text-center pb-6 pt-1">
          Let’s get started
        </h2>

        <div className="flex flex-wrap justify-between lg:px-24 my-6">
          {/* {productsData.map((product) => (
            <div key={product.id} className="w-full md:w-1/3 lg:w-1/4 xl:w-1/4">
            
            </div>
          ))} */}
          {productsData.map(
            (
              product,
              index 
            ) => (
              <div
                key={product.id}
                className={`w-full md:w-1/3 lg:w-1/4 xl:w-1/4 ${
                  index === productsData.length - 1 ? "last-card" : ""
                }`}
              >
                <div
                  className={`h-[16rem] items-center text-center shadow-lg rounded-3xl px-4 py-8 mb-8 mx-12 md:mx-8 ${product.backgroundColor} hover:border-green-400 hover:border-2`}
                >
                  {product.icon && (
                    <div className="flex text-center justify-center">
                      <img src={product.icon} alt="icon" className="h-12 p-2" />
                    </div>
                  )}
                  <h3 className={`font-bold mt-4 text-sm ${product.textColor}`}>
                    {product.header}
                  </h3>
                  {product.showLogin && (
                    <div className="my-4">
                      <a
                        href={product.signupLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <button
                          className={`bg-black text-white px-4 py-2 disabled:${product.disabledValue} rounded-xl hover:${product.hoverColor} hover:text-white hover:border-2`}
                        >
                          GET STARTED
                        </button>
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GetStarted;
