import React from "react";
import phone from "../assets/img/Group 35748.svg";

const Unblock = () => {
  return (
    <div className="flex flex-col-reverse md:flex-row lg:flex-row xl:flex-row px-12 py-8 md:p-24 background-color1">
      <div className="flex items-center justify-center w-full md:w-1/2 py-4">
        <img src={phone} alt="phone" />
      </div>
      <div className="w-full md:w-1/2 mb-4 md:ml-20">
        <h3 className="text-3xl mt-10 text-center md:text-left font-black md:w-[450px]">Unlock the Power of Seamless Payments</h3>
        <p className="mt-6 text-center md:leading-[3rem] md:text-left md:w-[560px]">
          Uniswitch is a revolutionary platform that empowers businesses to
          accept payments with ease, flexibility, and security. Whether you're a
          merchant, a marketplace, or a platform, our solution enables you to
          transact effortlessly with virtually any payment service, expanding
          your reach and growing your business like never before.
        </p>
      </div>
    </div>
  );
};

export default Unblock;
