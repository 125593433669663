import React from 'react'
import Sec from '../components/Sec'
import OurCompliance from '../components/OurCompliance'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'

const Security = () => {
  return (
    <div className='background-color1'>
        <Navbar />
        <Sec />
        <OurCompliance />
        <Footer />
    </div>
  )
}

export default Security