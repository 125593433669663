import React, { useState } from "react";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import ProductItem from "../components/ProductItem";
import ProductItemRight from "../components/ProductItemRight";
import os from "../assets/img/Group os.svg";
import ks from "../assets/img/kukusend.png";
import pm from "../assets/img/Group PM.svg";
import sarepay from "../assets/img/Group sarepay.svg";
import hrdesk from "../assets/img/hrdesk.png";
import billerHq from "../assets/img/Group biller hq.svg";

const Products = () => {
  const [showMore, setShowMore] = useState(false);

  const show = () => {
    setShowMore(!showMore);
  };

  return (
    <div className="background-color1">
      <Navbar />
      <div className="flex justify-center text-center">
        <h2 className="card md:text-5xl text-5xl font-black py-12 text-center">
          Products
        </h2>
      </div>
      <ProductItemRight
        name="SarePay"
        title="Send & Receive Payments with SarePay"
        description="Effortlessly manage payments across multiple channels from a single platform."
        image={sarepay}
        url="https://sarepay.com"
      />
      <ProductItem
        name="Octopus"
        title="Empower Your Payments with our Ultimate Management Solution."
        description="Effortlessly manage payments across multiple channels from a single platform."
        image={os}
        url={""}
        live={true}
      />
      <ProductItemRight
        name="HRDesk"
        title=" Enhancing Efficiency, Empowering Your Workforce"
        description="HR Desk supports businesses of all sizes, providing comprehensive HR task management."
        image={hrdesk}
        url="https://hrdesk.africa"
      />

      {showMore && (
        <div>
          <ProductItem
            name="KukuSend"
            title="Send money across countries with ease"
            description="KukuSend is a cutting-edge cross-border payment platform that allows
            you to send and receive money globally in different currencies,
            making international transactions seamless and secure"
            image={ks}
            url="https://kukusend.com"
          />
          <ProductItemRight
            name="BillerHQ"
            title="Streamline Your Payments, Simplify Your Life with BillerHQ"
            description="With BillerHQ, paying bills online has never been easier. Subscribe
            to all your favorite channels, buy electricity, airtime and data all
            on one platform."
            image={billerHq}
            url="https://billerhq.com"
          />
          <ProductItem
            name="Pension Manager"
            title=" Seamlessly Manage your Pension with Ease with Pension Manager"
            description=" Pension Manager is a powerful multi-channel management platform
            designed exclusively for PFAs/PFCs."
            image={pm}
            url="https://pensionmanager.ng"
          />
        </div>
      )}
      <div className="flex justify-center py-8">
        <button
          className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded shadow-lg"
          onClick={show}
        >
          {showMore ? "See Less" : "See More"}
        </button>
      </div>
      <Footer />
    </div>
  );
};

export default Products;
