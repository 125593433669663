import React from "react";
import Navbar from "./Navbar";
import { useRef } from "react";
import Footer from "./Footer";

const Privacy = () => {
  const section0Ref = useRef(null);
  const section01Ref = useRef(null);
  const section02Ref = useRef(null);
  const section03Ref = useRef(null);
  const section04Ref = useRef(null);
  const section05Ref = useRef(null);
  const section06Ref = useRef(null);
  const section07Ref = useRef(null);

  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section6Ref = useRef(null);
  const section7Ref = useRef(null);
  const section8Ref = useRef(null);
  const section9Ref = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="app-wrapper background-color1">
      <div className="sticky top-0 background-color1">
        <Navbar className="" />
      </div>

      <div className="flex-grow max-w-[83rem] md:mx-auto w-full">
        <div className="flex">
          <div className="hidden md:w-2/5 md:block">
            <nav className="md:mr-32 bg-gray-600 py-4 shadow-md text-white mt-1 rounded-lg">
              <ul>
                <li>
                  <button className="px-2 py-2 w-full text-left font-bold text-2xl">
                    Website Privacy Policy
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section0Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Introduction
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section01Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Who are we?
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section02Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    When you submit an enquiry via our website
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section03Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    When you purchase a product from our website
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section04Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Your rights as a data subject
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section05Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Your right to complain
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section06Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Updates to this privacy policy
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section07Ref)}
                    className="px-2 py-2 w-full text-left font-black text-2xl"
                  >
                    Terms of Use
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section2Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Account Terms
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section3Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Merchant Responsibilities
                  </button>
                </li>

                <li>
                  <button
                    onClick={() => scrollToSection(section6Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Terms of the Agreements and Program
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section7Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Limitations of Liability
                  </button>
                </li>
                <li>
                  <button
                    onClick={() => scrollToSection(section8Ref)}
                    className="px-2 py-2 w-full text-left font-medium"
                  >
                    Miscellaneous
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <div></div>
          <div></div>
          <div className="w-full md:w-3/5 box">
            <div className="md:">
              <div className="mb-8 p-6" id="policy-section" ref={section0Ref}>
                <h2 className="text-4xl font-bold py-6">
                  Website Privacy Policy
                </h2>
                <p>Document Effective August 01 2023.</p>
                <p>Last Updated May 15 2024.</p>
                <h3 className="text-2xl font-bold py-4">1. Introduction</h3>
                <p className="leading-[2rem] py-2" ref={section01Ref}>
                  This privacy policy tells you about the information we collect
                  from you when you use our website. In collecting this
                  information, we are acting as a data controller and, by law,
                  we are required to provide you with information about us,
                  about why and how we use your data, and about the rights you
                  have over your data.
                </p>
                <h3 className="text-2xl font-bold py-4" ref={section02Ref}>
                  1.1 Who are we?
                </h3>
                <p className="leading-[2rem] py-2">
                  We are Uniswitch Technology Limited. Our address is 15 Moore
                  Road Yaba, Lagos. <div ref={section02Ref}></div>
                  You can contact us by post at the above address, by email at
                  help@uniswitchng.com or by telephone at 08033792075.
                </p>

                <h3 className="text-2xl font-bold py-4">
                  1.2 When you submit an enquiry via our website
                </h3>
                <div className="leading-[2rem] py-2 space-y-2">
                  <p>
                    When you submit an enquiry via our website, we ask you for
                    your name, contact telephone number and email address.
                  </p>
                  <p>
                    We use this information to respond to your query, including
                    providing you with any requested information about our
                    products and services. We may also email you several times
                    after your enquiry in order to follow up on your interest
                    and ensure that we have answered your it to your
                    satisfaction. We will do this based on our legitimate
                    interest in providing accurate information prior to a sale.
                  </p>
                  <div ref={section03Ref}></div>
                  <p>
                    Users enquiries are stored in our database and also sent as
                    an email notification to registered customer support emails.
                  </p>
                  <p>
                    We do not use the information you provide to make any
                    automated decisions that might affect you.
                  </p>
                  <p>
                    We keep enquiry emails for two years, after which they are
                    securely archived and kept for seven years, when we delete
                    them. CRM records are kept for three years after the last
                    contact with you.
                  </p>
                </div>
                <h3 className="text-2xl font-bold py-4">
                  1.3 When you purchase a product from our website
                </h3>
                <div className="leading-[2rem] py-2 space-y-2">
                  <p>
                    When you purchase products from us online, we ask you for
                    your name, address, contact telephone number, email address
                    and debit card information. We also record your IP (Internet
                    Protocol) address, which is the address of your computer on
                    the Internet.
                  </p>
                  <p>
                    We will use your information to verify your debit card
                    details for your purchase, process your order and to send
                    you your products. We will also send you a receipt via email
                    and we may use your telephone number to contact you
                    regarding your purchase.
                  </p>
                  <p>
                    We require this information in order to process your
                    payment, deliver your products or services and fulfil our
                    contract with you.
                  </p>
                  <p>
                    Your information is stored on our website and on our cloud
                    server. Your debit card details are passed to a third-party
                    payment processor which is based in Nigeria and with whom we
                    have a contractual agreement which provides effective
                    safeguards for your information. We do not retain your debit
                    card information.
                  </p>
                  <p>
                    We do not use the information you provide to make any
                    automated decisions that might affect you.
                  </p>
                  <p>
                    We can reuse the authorization token gotten after a
                    successful card charging to re-charge a card for a service
                    that requires periodic recurring payments.
                  </p>
                  <p>
                    We keep your order information for an indefinite period, as
                    the licenses we sell for our products are renewed.
                  </p>
                </div>
                <h3 className="text-xl font-bold py-2">
                  Consent-based signups
                </h3>
                <div
                  className="leading-[2rem] py-2 space-y-2"
                  ref={section04Ref}
                >
                  <p>
                    The personal data collected include: Name, Email address,
                    phone number
                  </p>
                  <p>
                    The purpose of this transaction is to onboard users to our
                    platform/services
                  </p>
                  <p>The lawful basis used is consensual agreement.</p>
                  <p>
                    Personal data collected could be shared with third-party
                    service providers.
                  </p>
                  <p>
                    The personal data is stored in our cloud hosted database.
                  </p>
                  <p>No automated decisions are made on the data.</p>
                  <p>
                    The retention policy for personal user data is indefinite.
                  </p>
                </div>
                <h3 className="text-2xl font-bold py-4">
                  1.4 Your rights as a data subject
                </h3>
                <div
                  className="leading-[2rem] py-2 space-y-2"
                  ref={section05Ref}
                >
                  <p>
                    By law, you can ask us what information we hold about you,
                    and you can ask us to correct it if it is inaccurate. If we
                    have asked for your consent to process your personal data,
                    you may withdraw that consent at any time.
                  </p>
                  <p>
                    If we are processing your personal data for reasons of
                    consent or to fulfil a contract, you can ask us to give you
                    a copy of the information in a machine-readable format so
                    that you can transfer it to another provider.
                  </p>
                  <p>
                    If we are processing your personal data for reasons of
                    consent or legitimate interest, you can request that your
                    data be erased.
                  </p>
                  <p>
                    You have the right to ask us to stop using your information
                    for a period of time if you believe we are not doing so
                    lawfully.
                  </p>
                  <p>
                    Finally, in some circumstances you can ask us not to reach
                    decisions affecting you using automated processing or
                    profiling.
                  </p>
                  <p>
                    To submit a request regarding your personal data by email,
                    post or telephone, please use the contact information
                    provided above in the Who Are We section of this policy.
                  </p>
                </div>
                <h3 className="text-2xl font-bold py-4">
                  1.5 Your right to complain
                </h3>
                <div
                  className="leading-[2rem] py-2 space-y-2"
                  ref={section06Ref}
                >
                  If you have a complaint about our use of your information, we
                  would prefer you to contact us directly in the first instance
                  so that we can address your complaint. However, you can also
                  contact the Data Protection Commission via their website at
                  &nbsp;
                  <a href="https://ndpc.gov.ng/">https://ndpc.gov.ng/</a> or
                  write to them at:
                  <p className=" flex flex-col">
                    <span>Nigeria Data Protection Bureau</span>
                    <span>No, 5 Donau Crescent</span>
                    <span>Off Amazon Street,</span>
                    <span>Maitama Abuja, Nigeria.</span>
                  </p>
                </div>
                <h3 className="text-2xl font-bold py-4">
                  1.6 Updates to this privacy policy
                </h3>
                <div
                  className="leading-[2rem] py-2 space-y-2"
                  ref={section07Ref}
                >
                  <p>
                    We regularly review and, if appropriate, update this privacy
                    policy from time to time, and as our services and use of
                    personal data evolves. If we want to make use of your
                    personal data in a way that we have not previously
                    identified, we will contact you to provide information about
                    this and, if necessary, to ask for your consent.
                  </p>
                  <p>
                    We will update the version number and date of this document
                    each time it is changed.
                  </p>
                </div>
              </div>
              <div className="p-4">
                <div className="mb-8" ref={section2Ref}>
                  <h2 className="text-4xl font-bold mb-6" id="terms">
                    Terms of Use
                  </h2>
                  <h2 className="text-2xl font-bold mt-4 mb-4">
                    Uniswitch Technology Merchant Terms of Service
                  </h2>
                  <p className="leading-[2rem]">
                    Uniswitch Technology reserves the right to update and change
                    the Terms of Service from time to time without notice. Any
                    new features that augment or enhance the current Program,
                    including the release of new tools and resources, shall be
                    subject to the Terms of Service. Continued use of the
                    Program after any such changes shall constitute your consent
                    to such changes. Violation of any of the terms below will
                    result in the termination of Merchant Account and forfeiture
                    of any outstanding commission payments earned during the
                    violation.
                  </p>
                </div>
                <div className="mb-8" ref={section3Ref}>
                  <h2 className="text-2xl font-bold mb-4">Account Terms</h2>
                  <p className="leading-[2rem]">
                    You must be 18 years or older to be part of this Program.
                    You must provide your legal full name, a valid email
                    address, and any other information requested in order to
                    complete the signup process. Your login may only be used by
                    one person – a single login shared by multiple people is not
                    permitted. You are responsible for maintaining the security
                    of your account and password. Uniswitch Technology Limited
                    cannot and will not be liable for any loss or damage from
                    your failure to comply with this security obligation. You
                    are responsible for all activities that occur under your
                    account. One person or legal entity may not maintain more
                    than one account. You may not use the Merchant service for
                    any illegal or unauthorized purpose. In the use of the
                    Service, you must not violate any laws in your jurisdiction.
                  </p>
                </div>

                <div className="mb-8" ref={section6Ref}>
                  <h2 className="text-2xl font-bold mb-4">
                    Merchant Responsibilities
                  </h2>
                  <p className="leading-[2rem]">
                    Merchants and Payment Facilitators will be solely
                    responsible for the operation and maintenance of their
                    infrastructure and system.
                  </p>
                </div>
                <div className="mb-8" ref={section7Ref}>
                  <h2 className="text-2xl font-bold mb-4">
                    Terms of the Agreements and Program
                  </h2>
                  <p className="leading-[2rem]">
                    The terms of this Agreement will begin upon registration as
                    a Merchant and will end when terminated by either party.
                    Either Uniswitch or yourself may terminate this Agreement at
                    any time, with or without cause, by giving the other party
                    written notice of termination. Upon the termination of this
                    Agreement for any reason, you will immediately cease use of
                    and return all Uniswitch’s materials provided by or on
                    behalf of us to you pursuant hereto or in connection with
                    the Program.
                  </p>
                </div>
                <div className="mb-8" ref={section8Ref}>
                  <h2 className="text-2xl font-bold mb-4">
                    Limitations of Liability
                  </h2>
                  <p className="leading-[2rem]">
                    We will not be liable for indirect, special, or
                    consequential damages (or any loss of revenue, profits, or
                    data) arising in connection with this Agreement or the
                    Program, even if we have been advised of the possibility of
                    such damages.
                  </p>
                </div>
                <div className="mb-8" ref={section9Ref}>
                  <h2 className="text-2xl font-bold mb-4">Miscellaneous</h2>
                  <p className="leading-[2rem]">
                    This Agreement will be governed by the laws of the State,
                    without reference to rules governing the choice of laws. You
                    may not assign this Agreement by law or otherwise without
                    our prior written consent. Subject to that restriction, this
                    Agreement will be binding on, inure to the benefit of, and
                    be enforceable against the parties and their respective
                    successors and assigns. Our failure to enforce your strict
                    performance of any provision of this Agreement will not
                    constitute a waiver of our right to subsequently enforce
                    such provision or any other provision of this Agreement.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer className="isms-footer" />
    </div>
  );
};

export default Privacy;
