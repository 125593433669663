import React from "react";
import { AiOutlineMail } from "react-icons/ai";

const HeroText = () => {
  return (
    <div className="flex h-1/2 md:h-screen">
      <div className="flex items-center mt-12 justify-center lg:w-1/2">
        <div className="text-left mx-2">
          <p className="text-5xl mx-auto md:mx-2 md:text-6xl text-white text-center leading-[4rem] md:leading-[6rem] font-black md:text-left w-[300px] md:w-[380px]">
            Payment Infrastructure for Everyone
          </p>
          <p className="text-white mx-auto leading-[2rem] px-4 md:text-left md:w-[520px] mt-6 text-center md:mx-2">
            Leading-edge technology connecting African banks, payment service
            providers and other financial market intermediaries enabling instant
            and secure payments between African countries
          </p>
          {/* <form className="flex mt-8 text-sm">
            <div className="flex border border-black rounded-full">
              <AiOutlineMail
                className="flex-none self-center ml-2 mr-1 text-gray-500 text-xl"
                style={{ color: "white" }}
              />
              <input
                type="email"
                placeholder="Enter your email"
                id="email"
                className="
                  p-1
                 md:pr-12
                  rounded-full
                  bg-transparent
                  focus:outline-none
                  text-white
                  placeholder-white
                "
              />
              
              <button
                className="
                  p-4
                  hover:bg-green-600
                  hover:border-green-600
                  text-white
                  border
                  border-solid
                  border-black
                  bg-black
                  rounded-full
                  ml-12
                "
                type="submit"
              >
                Get Started
              </button>
            </div>
          </form> */}
        </div>
      </div>
      <div className="hidden md:w-1/2">{/* Empty space on the right */}</div>
    </div>
  );
};

export default HeroText;
