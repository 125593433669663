const faqData = [
  {
    question: "Is Uniswitch secure?",
    answer: "Yes",
  },
  {
    question: "Do my client and vendors have to sign up for Upay?",
    answer: "lorem.",
  },
  {
    question: "Can I accept payments from customers globally with Uniswitch?",
    answer: "lorem.",
  },
  {
    question: "How does Uniswitch make money?",
    answer: "lorem.",
  },
  {
    question: "Are the payments really instant?",
    answer: "lorem.",
  },
  {
    question: "Does Uniswitch replace my accounting software?",
    answer: "lorem.",
  },
];

export default faqData;
