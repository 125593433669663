import React from "react";
import icon1 from "../assets/img/Rectangle 4.svg";
import icon2 from "../assets/img/iso_27001-removebg-preview 2.svg";
import icon3 from "../assets/img/images iso 1.svg";
import icon4 from "../assets/img/NDPC.png";

const OurCompliance = () => {
  const productsData = [
    {
      id: 1,
      icon: icon1,
      header: "Payment Card Industry Certified",
      text: "PCI DSS",
    },
    {
      id: 2,
      icon: icon2,
      header: "Management System",
      text: "ISO 27001",
    },
    {
      id: 3,
      icon: icon3,
      header: "Management System",
      text: "ISO 27002",
    },
    {
      id: 4,
      icon: icon4,
      header: "Nigeria Data Protection Commission",
    },
  ];

  return (
    <div className="px-4 py-8">
    <h2 className="card md:text-4xl text-3xl font-bold text-center">
      Our Compliance Certifications
    </h2>
  
    <div className="flex flex-wrap justify-between lg:px-24 my-6">
      {productsData.map((product) => (
        <div key={product.id} className="w-full md:w-1/4 lg:w-1/4 xl:w-1/4">
          <div className="items-center text-center h-64 bg-white text-black shadow-lg rounded-3xl py-10 mb-14 mx-24 md:mx-8">
            <div className="flex items-center justify-center">
              <img src={product.icon} alt="icon" className="h-20" />
            </div>
            <h3 className="font-bold text-xl mt-4">{product.header}</h3>
            <p className="text-xs mt-4">{product.text}</p>
          </div>
        </div>
      ))}
    </div>
  </div>
  
  );
};

export default OurCompliance;
